import React from "react";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import { values } from "../../utils/constants";

const Values = () => {
  return (
    <section
      className="pb-[40px] lg:pb-[140px] px-8 lg:px-32 pt-[80px]"
     
    >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} `}
      >
        <div className=" text-center">
          <motion.h1
            variants={fadeIn("right", "tween", 0.3, 0.9)}
            className={`${styles.heroHeading}  text-secondary   text-center lg:text-left mb-4 estampa`}
          >
            Our Values
          </motion.h1>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center">
          {values.map((values, i) => (
            <motion.div
              variants={fadeIn("right", "spring", i * 0.3, 0.9)}
              key={i}
              className=" bg-gradient-to-r from-[#5E1600] via-[#240800] to-black  rounded-[20px] p-10 lg:p-16 shadow-md flex items-start flex-col space-y-8 h-fit"
            >
              <h1 className="font-bold  text-white text-[25px] lg:text-[32px] leading-4">
                {values.title}
              </h1>
              <p className="text-white text-[18px]">{values.desc}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Values;
