import { React, useCallback, useState, useEffect } from "react";
import Logo from "../img/White on Blue.svg";
import LogoWhite from "../img/logo-white.svg";
import { Link, useLocation } from "react-router-dom";
import { MobileMenu } from "../components";

const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [active, setActive] = useState(false);

    const handleClick = useCallback(() =>{
        setActive(false);
    }, []);

    const [scroll, setScroll] = useState(false);

     //check the navigation on scroll

  const isActive = () => {
    window.scrollY > 120 ? setScroll(true) : setScroll(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isActive);

    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);


  return (
    <header className={`${scroll ? 'bg-black backdrop-filter backdrop-blur-sm bg-opacity-50 rounded-b-xl' : 'bg-white ' } py-4 px-8 lg:px-32  sticky top-0 left-0  m-auto w-[98%] z-10 mb-6 rounded-b-2xl shadow-xl` }>
      <nav className={`${scroll ? 'text-white' : 'text-[#024058]'} flex items-center  h-full relative`}>
        <div className="w-[25%] lg:w-[20%] logo">
          <Link to="/" onClick={handleClick}  >
          <img className="w-full lg:w-[150px]" src={scroll ? Logo : LogoWhite} alt="" />
           
          </Link>
        </div>

        <div className="flex-1 hidden lg:block">
          <div className="Links  flex justify-center gap-20 items-center text-[20px] pt-2 font-bold">
            <Link
              to="/about"
              className={`${
                splitLocation[1] === "about" ? "text-primary" : ""
              } hover:text-primary transition ease-in `}
            >
              <h6>About</h6>
            </Link>

            <Link
              to="/portfolio"
              className={`${
                splitLocation[1] === "portfolio" ? "text-primary" : ""
              } hover:text-primary transition ease-in `}
            >
              <h6>Portfolio</h6>
            </Link>
          
            <Link
              to="/booking"
              className={`${
                splitLocation[1] === "booking" ? "text-primary" : ""
              } hover:text-primary transition ease-in `}
            >
              <h6>Bookings</h6>
            </Link>

          
          </div>
        </div>

        <div className="w-[20%]  items-center  flex-end  justify-end hidden lg:flex">
         <a href="#footer" className={`${scroll ? 'border-white  text-white ': ' border-primary  text-primary ' } rounded-sm text-[18px] space-x-2 border-2 font-bold italic py-2 px-6 `}>
         <span className="estampa font-bold">Follow+Join</span>
          <i class="fa-sharp fa-solid fa-arrow-down text-[16px] animate-bounce "></i>
         </a>
       
        
        </div>


        <div className=" ml-auto">
          <button onClick={() => setActive(!active)} className="flex lg:hidden">
            {active  ? (
             <i class="fa-solid fa-xmark text-[24px]"></i>
          
            ) : (
                <i class="fa-solid fa-bars text-[24px]"></i>
            )}
          </button>
        </div>
      </nav>

      {active &&   <MobileMenu handleClick={handleClick}   />}

     
     
    </header>
  );
};

export default Navbar;
