import React, {useEffect} from 'react'
import { IntroBg, AboutSection, Services, Collaboration,  } from '../sections/Home';



const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <div>
     <IntroBg/>
     <AboutSection/>
     <Services/>
     <Collaboration/>
   {/* <Testimonials/> */}

    </div>    
    
    )
}

export default Home