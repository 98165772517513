import React from 'react'
import BashBg from '../../img/bash-bg.png'
import { motion } from 'framer-motion';
import { FiExternalLink } from "react-icons/fi";
import {fadeIn, slideIn, staggerContainer} from '../../utils/motion';
import { Link } from 'react-router-dom';




const IntroBg = () => {
  return (
    <section className="  rounded-2xl w-[98%]  h-[450px] lg:h-[750px] mb-[-2px] m-auto " style={{backgroundImage: `linear-gradient(90deg, rgba(94,22,0,0.6) 0%, rgba(36,8,0,0.9) 50%, rgba(0,0,0,0.8) 100%), url(${BashBg})`, backgroundSize: `cover`,  }}>
 <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={` mx-auto flex flex-col h-full`}
    >
    <div className=" flex items-center h-full flex-col justify-center font-bold ">
      <div  className="text-center text-white">
      <motion.h1 variants={slideIn('up', 'tween', 0.2, 1)} className={`text-[70px] lg:text-[150px] leading-[20px]   lg:leading-[60px] `}>
            BashPlug 
        </motion.h1>
        <motion.h1 variants={slideIn('up', 'tween', 0.2, 1)} className={`text-[35px] pb-8 lg:pb-0 lg:text-[80px] leading-[20px] lg:leading-[120px] pt-10`}>
            Entertainment 
        </motion.h1>
      </div>
    <div>
  
        <motion.div
          variants={fadeIn('down', 'tween', 0.2, 1)}
          className=""
        >
          <Link to="/booking" className="flex text-white justify-center items-center h-fit py-4 px-6 border-2 border-white  hover:bg-primary hover:border-primary  gap-[4px]">
          {/* <img src={Headset} alt="headset" className="w-[24px] h-[24px] object-contain" /> */}
         
          <span className="font-normal text-[16px] ">MAKE BOOKINGS NOW</span>

          <FiExternalLink />
          </Link>
       
        </motion.div>
      

        
      </div>
    </div>
    </motion.div>
    </section>

  )
}

export default IntroBg