import React from "react";
import { fadeIn, staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";

// import {  FaYoutube } from "react-icons/fa";

import Chevron from "../../img/chevron-red.png";
import Disc from "../../img/jingle.png";

import { jingles } from "../../utils/constants";

const Jingle = () => {
  return (
    <section className="pb-[60px]  lg:pb-[140px] px-8 lg:px-32 pt-[80px] w-[98%]  m-auto">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={` grid grid-cols-1 lg:grid-cols-3 `}
      >
        <div className="left-side space-y-10 lg:space-y-4 pb-6">
          <motion.div
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="left-section flex items-center gap-2 lg:text-left text-center "
          >
            <img src={Chevron} className="w-4 h-7" alt="" srcset="" />
            <h1 className={`text-primary capitalize text-[16px] font-bold`}>
              JINGLE PRODUCTIONS
            </h1>
          </motion.div>

          <motion.img
            src={Disc}
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="lg:w-[200px]"
          ></motion.img>
        </div>

        <div className="right-side col-span-2 space-y-4">
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className=" pb-2 estampa font-normal lg:text-[18px] text-[20px]  leading-12 text-left text-[#909090]"
          >
            BashAnything has also made a significant impact in the advertising
            industry through his remarkable jingle productions. Renowned
            organizations such as Lagos State, Airtel, GTBank, Zenith Bank,
            FCMB, Binatone, Adron Homes, 9mobile, Credit Day, and many more have
            sought his creative touch to capture the essence of their brands.
            BashAnything's ability to create captivating audio experiences has
            solidified his position as the go-to choice for jingle production.
          </motion.p>

          <motion.div variants={fadeIn("up", "tween", 0.2, 1)}>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
              {jingles.map((jingle, i) => (
                <div className="flex lg:items-center items-start gap-4 flex-col" key={i}>
                  <img src={jingle.img} alt={jingle.brandName} className="rounded-[20px] w-[30px] h-[30px]  lg:w-[64px] lg:h-[64px] " srcset=""  />

                  <div className="">
                    <h1 className="text-[18px] estampa">{jingle.brandName}</h1>

                    {/* <div className="inline-flex items-center space-x-1 text-primary">
                    <FaYoutube />
                    <a
                      className="text-[#909090] hover:text-secondary underline text-[16px]"
                      href={jingle.link}
                    >
                      {" "}
                     
                      <h1>View</h1>
                      </a>
                    </div>
                   
                      */}
                
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Jingle;
