import React, {useEffect} from 'react'
import { Welcome, Values, Ceo,Vision, Mission} from '../sections/About'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <>
   <Welcome/>
   <Ceo/>
   <Vision/>
   <Mission/>
   <Values/>
   </>
  )
}

export default About