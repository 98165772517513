import React, { useRef, useState } from "react";

import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import { AiFillInstagram } from "react-icons/ai";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import Chevron from "../../img/chevron-red.png";


const Form = () => {
  //setting up recaptcha token
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [err, setErr] = useState("");
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setErr("");
    console.log(token);
  };

  const form = useRef();

  console.log(form);

  const [loading, setLoading] = useState(false);

  const service_id = process.env.REACT_APP_SERVICE_ID;
  const template_id = process.env.REACT_APP_TEMPLATE_ID;
  const public_key = process.env.REACT_APP_PUBLIC_KEY;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaToken) {
      if (
        form.current[0].value === "" ||
        form.current[1].value === "" ||
        form.current[2].value === "" ||
        form.current[3].value === "" 
      ) {
        toast.error("Please Fill all required fields to submit! 😢");
      } else {
        emailjs
          .sendForm(service_id, template_id, form.current, public_key)
          .then(
            (result) => {
              recaptchaRef.current.reset();
              setLoading(false);
              toast.success("Message sent successfully!🎉🎉🎉");
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
              toast.error(error.text);
            }
          );

        setLoading(true);
        form.current.reset();
      }
    } else {
      toast.error("Please complete the reCaptcha");
    }
  };

  return (
    <section className="pb-[60px]  lg:pb-[140px] lg:px-32 pt-[80px] w-[98%]  m-auto">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} `}
      >
        <section className=" flex flex-col lg:flex-row items-center gap-10 ">
          <motion.div
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="left-side rounded-[15px] bg-gradient-to-r from-[#5E1600] via-[#240800] to-black py-10 px-8 contact-form w-full lg:w-1/2 space-y-6 lg:self-end"
          >
            <div className="left-section flex items-center gap-2 lg:text-left text-center ">
              <img src={Chevron} className="w-4 h-7" alt="" srcset="" />
              <h1 className={`text-primary capitalize text-[16px] font-bold`}>
                MAKE BOOKINGS
              </h1>
            </div>

            <p className=" pb-2 estampa font-normal lg:text-[35px] text-[20px]  leading-[28px] text-left text-white">
              Get Intouch with Me
            </p>

            <form
              action=""
              className="w-full  space-y-6"
              ref={form}
              onSubmit={handleSubmit}
            >
              <div className="flex-col space-y-2   text-white text-sm mb-2">
              <label className="text-[18px]">Fullname:</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Fullname"
                  className="bg-transparent focus:ring-0 outline-none w-full border border-gray-400 rounded-md px-3 py-3"
                />
              </div>

              <div className="flex-col space-y-2  text-white text-sm mb-2">
                <label className="text-[18px]">Email:</label>
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="bg-transparent focus:ring-0 outline-none w-full  border border-gray-400 rounded-md px-3 py-3"
                />
              </div>

              <div className="flex-col space-y-2  text-white text-sm mb-2">
                <label className="text-[18px]">Phone:</label>
                <input
                  name="phone"
                  type="phone"
                  placeholder="Phone"
                  className="bg-transparent focus:ring-0 outline-none w-full border border-gray-400 rounded-md px-3 py-3"
                />
              </div>
              <div className="flex-col space-y-2  text-white text-sm mb-2">
            <label className="text-[18px]">Services:</label>
                <select
                  name="services"
                  className="bg-transparent focus:ring-0 outline-none w-full  border border-gray-400 rounded-md px-3 py-3"
                  
                >
                  <option selected>Select</option>
                  <option value="PR Consultant">PR Consultant</option>
                  <option value="Talent Manager">Talent Manager</option>
                  <option value="Music Producer">Music Producer</option>
                  <option value="Event Manager">Event Manager</option>
                  <option value="Music Promoter">Music Promoter</option>
                  </select>
              </div>

              <div className="flex-col w-full space-y-2  text-white text-sm mb-2">
              <label className="text-[18px]">Message:</label>
                <textarea
                  name="message"
                  row="80"
                  col="10"
                  placeholder="Message"
                  className="bg-transparent focus:ring-0 outline-none w-full border border-gray-400 rounded-md px-3 py-3"
                ></textarea>
              </div>

              <ReCAPTCHA
                onChange={onRecaptchaChange}
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={recaptchaRef}
              />
              <p className="text-left text-red-600 mt-4">{err}</p>
              <div className="w-full flex justify-start">
                <button
                  className="border text-black flex items-center hover:bg-slate-200 px-10 py-3 space-x-2 border-white bg-white"
                  type="submit"
                >
                  {loading ? <Loader /> : "Submit Form"}
                </button>
              </div>
            </form>
          </motion.div>

          <div className="right-side flex flex-col gap-[32px]  text-secondary self-start w-full lg:w-2/5">
            
            <div className="px-[16px] lg:px-[24px] border-t-[1.5px] lg:border-t-0 py-[24px] lg:py-0 lg:border-l-[1.5px] border-primary ">
            <div className="">
              <p className="mb-4 text-[#545454] text-[16px]">Email Address:</p>
              <h1 className="font-bold">
                <a href="mailto:mgt@bashplugent.com">mgt@bashplugent.com</a>
              </h1>
            </div>

            <div className="">
              <p className="mb-4 text-[#545454] text-[16px]">Phone Number:</p>
              <h1 className="font-bold ">
                <a href="tel:+2349040308649">+234 904 030 8649</a>
              </h1>
            </div>

            <div className="socials flex flex-col self-start  gap-2">
              <p className=" text-[16px]">Follow on Social Media</p>

              <a
                href="https://instagram.com/bashanything?igshid=NjIwNzIyMDk2Mg=="
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                <AiFillInstagram size="1.5rem" />
              </a>
            </div>
            </div>

          


          </div>
        </section>
      </motion.div>
    </section>
  );
};

export default Form;
