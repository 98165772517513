import React from 'react'
import BashAbout from "../img/bash-about.jpg"
import image1 from "../img/image1.jpeg"
import image2 from "../img/image2.jpeg"
import { Zoom } from "react-slideshow-image";

const Slideshow = () => {
    const images = [BashAbout, image1, image2,]

    const zoomOutProperties ={
        duration: 2000,
        transitionDuration: 500,
        infinite: true,
        scale: 0.4,
        arrows: false,
      } 

  return (
    <div className="slide-container">
         <Zoom {...zoomOutProperties} >
          {images.map((image, index)=> (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img key={index} style={{width: "100%", borderRadius: '30px'}} src={image}/>
          ))}

        </Zoom>
    </div>
  )
}

export default Slideshow