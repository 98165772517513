import React from "react";
import { motion } from "framer-motion";
import PortfolioBg from "../../img/bash-bg.png";

import { slideIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";

const Welcome = () => {
  return (
    <section
    className="  m-auto w-[98%] rounded-3xl pt-[80px] pb-[100px]  lg:pt-[100px] lg:pb-[160px]"
    style={{
      backgroundImage: `linear-gradient(90deg, rgba(94,22,0,0.9) 0%, rgba(36,8,0,0.9) 50%, rgba(0,0,0,0.9) 100%), url(${PortfolioBg})`,
      backgroundSize: `cover`,
      backgroundPositionY: `20%`

      
    }}
  >
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex flex-col h-full`}
    >
      <div className="text-[40px] flex items-center h-full justify-center font-bold">
        <div className="flex justify-center items-center flex-col ">
          <motion.h1
            variants={slideIn("up", "tween", 0.2, 1)}
            className={`${styles.heroHeading}  text-center text-white  estampa`}
          >
            Bookings
          </motion.h1>


        </div>
      </div>
    </motion.div>
  </section>
  )
}

export default Welcome