import React from 'react'
import { Welcome, Events, Jingle } from '../../sections/Portfolio'
import { Talents } from '../../sections/Portfolio/'

const Portfolio = () => {
  return (
    <>
   <Welcome/>
   <Talents/>
   <Events/>
   <Jingle/>
    </>

  )
}

export default Portfolio