import React,{useEffect} from "react";
import {fadeIn, slideIn, staggerContainer } from "../../../utils/motion";
import { motion } from "framer-motion";
// import { Link } from "react-router-dom";
import { talents } from "../../../utils/constants";
import Chevron from "../../../img/chevron-red.png"
// import { FiExternalLink } from "react-icons/fi";

const Talents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  if (talents.length === 0) {
    return <div>Loading....</div>
  }


  return (
    <section className="pb-[60px]  lg:pb-[80px] px-8 lg:px-32 pt-[80px] w-[98%]  m-auto">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`space-y-6`}
      >
        <motion.div
          variants={slideIn("up", "tween", 0.2, 1)}
          className="left-section flex items-center gap-2 lg:text-left text-center "
        >
          <img src={Chevron} className="w-4 h-7" alt="" srcset="" />
          <h1 className={`text-primary capitalize text-[16px] font-bold`}>
          COLLABORATIONS          </h1>
        </motion.div>

        <motion.p
          variants={slideIn("up", "tween", 0.2, 1)}
          className="lg:w-[45%] pb-2 estampa font-normal lg:text-[40px] text-[20px]  leading-[36px] text-left text-secondary"
        >
          BashPlug Entertainments is a dynamic and visionary brand
        </motion.p>


       <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 ">
          {talents.map((talent, i) => (
            <motion.div
              variants={fadeIn("right", "spring", i * 0.3, 0.9)}
              key={i}
              className="flex flex-col gap-6"
            >
              <div>
                <img
                  src={talent.img}
                  alt={talent.fullName}
             
                  className="w-[400px] h-[400px] rounded-[10px] "
                  srcset=""
                />
              </div>

              <h1 className="font-bold estampa text-secondary text-[25px] lg:text-[24px] leading-4">
                {talent.fullName}
              </h1>

              <div className="text-primary text-[20px]">
                <ul className="flex items-center gap-2">
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.instagram.link}
                      rel="noreferrer"
                    >
                      {talent.icons.instagram.icon}
                    </a>
                  </li>
                  {/* <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.twitter.link}
                      rel="noreferrer"
                    >
                      {talent.icons.twitter.icon}
                    </a>
                  </li> */}
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.apple.link}
                      rel="noreferrer"
                    >
                      {talent.icons.apple.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.spotify.link}
                      rel="noreferrer"
                    >
                      {talent.icons.spotify.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.youtube.link}
                      rel="noreferrer"
                    >
                      {talent.icons.youtube.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.tiktok.link}
                      rel="noreferrer"
                    >
                      {talent.icons.tiktok.icon}
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div>
                <Link to={`/portfolio/talents/${talent.id}`}
                  className="text-[#909090] hover:text-secondary underline inline-flex items-center space-x-1 text-[16px]"
              
                >
                  {" "}
                  <h1>View talent</h1>
                  <FiExternalLink />
                </Link>
              </div> */}
            </motion.div>
          ))}
        </div> 

        {/* <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 items-center">
          {talents.map((talent, i) => (
            <motion.div
              variants={fadeIn("right", "spring", i * 0.3, 0.9)}
              key={i}
              className="flex flex-col gap-6"
            >
              <div>
                <img
                  src={talent.img}
                  alt={talent.fullName}
                  onClick={(e) => {
                    setId(talent.id)
                  }}
                  className="w-[320px] h-[320px] rounded-[10px] "
                  srcset=""
                />
              </div>

              <h1 className="font-bold estampa text-secondary text-[25px] lg:text-[24px] leading-4">
                {talent.fullName}
              </h1>

              <div className="text-primary text-[20px]">
                <ul className="flex items-center gap-2">
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.instagram.link}
                      rel="noreferrer"
                    >
                      {talent.icons.instagram.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.twitter.link}
                      rel="noreferrer"
                    >
                      {talent.icons.twitter.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.apple.link}
                      rel="noreferrer"
                    >
                      {talent.icons.apple.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.spotify.link}
                      rel="noreferrer"
                    >
                      {talent.icons.spotify.icon}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      target="_blank"
                      href={talent.icons.youtube.link}
                      rel="noreferrer"
                    >
                      {talent.icons.youtube.icon}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <a href={viewTalent}
                  className="text-[#909090] hover:text-secondary underline inline-flex items-center space-x-1 text-[16px]"
                //  onClick={viewTalent}
                >
                  {" "}
                  <h1>View talent</h1>
                  <FiExternalLink />
                </a>
              </div>
            </motion.div>
          ))}
        </div> */}
      </motion.div>
    </section>
  );
};

export default Talents;
