import React from "react";
import { services } from "../../utils/constants";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";


const Services = () => {
  return (
    <section
      className=" px-8 lg:px-16  lg:pt-[80px] py-[60px] lg:pb-[70px]  m-auto w-[98%] rounded-3xl bg-gradient-to-r from-[#5E1600] via-[#240800] to-black "

    >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <motion.h1
          variants={slideIn("up", "tween", 0.2, 1)}
          className={`${styles.heroHeading}  text-center text-white mb-4 estampa`}
        >
          Our <span className="text-white">Services</span>
        </motion.h1>
        <motion.p
          variants={fadeIn("up", "tween", 0.2, 1)}
          className="lg:w-[50%] m-auto font-normal lg:text-[18px] text-[20px]   text-center text-white"
        >
          BashPlug Entertainments is a dynamic and visionary talent management
          and PR services company founded and led by Bashorun Biola Emmanuel,
          popularly known as BashAnything.
        </motion.p>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center">
          {services.map((service, i) => (
            <motion.div
              variants={fadeIn("right", "spring", i * 0.3, 0.9)}
              key={i}
              className=" p-10   flex items-center justify-center flex-col space-y-8"
            >
              <img
                className="w-[60px] h-[60px] p-2 bg-[#E1462A] rounded-[24px]"
                src={service.img}
                alt={service.title}
              />
              <h1 className="font-bold estampa  text-white text-[32px] ">
                {service.title}
              </h1>
            </motion.div>
          ))}
        </div>

        <div className="py-5 lg:pt-10 w-full">
          <Link
            to="/portfolio"
            className="flex text-center lg:w-1/5 justify-center m-auto text-white hover:text-white items-center 
               h-fit py-2 px-4 lg:py-3 lg:px-6 border-2 border-white hover:border-primary hover:bg-primary  gap-[4px]"
          >
            <p className="font-bold text-[16px] pt-1">VIEW PORTFOLIO</p>

            <FiExternalLink />
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default Services;
