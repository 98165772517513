import React from 'react'
// import BashBg from "../../img/black_texture.jpg"
// import { motion } from "framer-motion";
// import {  slideIn, staggerContainer } from "../../utils/motion";
// import styles from "../../styles";
import { partners} from '../utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';




const Partners = () => {


  return (
    <section
    className=" px-8 lg:py-10 lg:px-16  "
  >


<Swiper 
    spaceBetween={50}
    slidesPerView={3}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    modules={[Navigation, Autoplay]}
    breakpoints={{
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    }}

    >
      <></>

        {   partners.map((partner, i) =>(
          <SwiperSlide key={i}>
              <div className='grid align-middle'>
                    <img src={partner.logo} alt="" className='sm:w-[350px] lg:w-full'/>

    
                </div>
            

          </SwiperSlide>
               
            ))}
  

    </Swiper> 
      


</section>
  )
}

export default Partners