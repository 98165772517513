import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { talents } from '../utils/constants';
import { motion } from "framer-motion";
// import KennyBg from "../../../img/spyro-bg.jpg";
import { slideIn, staggerContainer } from "../utils/motion";
import styles from "../styles";



const TalentDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

const {id} = useParams();



  return (
    <div>
          {
            talents?.filter(talent =>talent.id === parseInt(id)).map(({ fullName, video, bg}, i ) => (
       
              <>
              <section
         
                className="  m-auto w-[98%] rounded-3xl pt-[80px] pb-[100px]  lg:pt-[100px] lg:pb-[160px]"
                style={{
                  backgroundImage: `linear-gradient(90deg, rgba(94,22,0,0.9) 0%, rgba(36,8,0,0.9) 50%, rgba(0,0,0,0.9) 100%), url(${bg})`,
                  backgroundSize: `cover`,
                  backgroundPositionY: `25%`,
                }}
              >
                <motion.div
                     key={i}
                  variants={staggerContainer}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.25 }}
                  className={`${styles.innerWidth} mx-auto flex flex-col h-full`}
                >
                  <div className="text-[40px] flex items-center h-full justify-center font-bold">
                    <div className="flex justify-center items-center flex-col ">
                      <motion.h1
                        variants={slideIn("up", "tween", 0.2, 1)}
                        className={`${styles.heroHeading}  text-center text-white  estampa`}
                      >
                        {fullName}
                      </motion.h1>
                    </div>
                  </div>
                </motion.div>
              </section>
          
              <div className=" px-8 lg:px-16  lg:pt-[80px] py-[60px] lg:pb-[70px] ">
                <motion.div
                  variants={staggerContainer}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.25 }}
                
                >

        <div  className=" gap-4 grid grid-cols-1 lg:grid-cols-3 items-center">
         {video.map((link, index) => (
         <div key={index}>
          <iframe
            loading="lazy"
            allowfullscreen="allowfullscreen"
            class="rounded-xl w-full h-96"
            width="560"
            height="315"
            src={link}
         title={fullName}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            
          ></iframe>
        </div>
      ))}
      </div>
   
               
                </motion.div>
              </div>
            </>
            ))
          }
        </div>

  
  
  )
}

export default TalentDetails;