import React from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";
import BashUk from "../../img/bash-uk.jpg";
import styles from "../../styles";

const Vision = () => {
  return (
    <section className="pb-[60px]  lg:pb-[80px] px-8 lg:px-32  pt-[80px] w-[98%]  m-auto">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} grid  lg:gap-0  grid-cols-1 lg:grid-cols-2 items-center `}
      >

        <div className="right-section pt-4 lg:pt-0 order-last lg:order-first">
          <motion.div
            variants={fadeIn("up", "tween", 0.3, 1)}
            className="lg:w-[450px] object-fit lg:mr-auto"
          >
            <img src={BashUk} alt="" srcset="" className="rounded-[30px] w-[400px] lg:mr-auto" />
          </motion.div>
        </div>


        <div className="left-section lg:text-left text-center ">
          <motion.h1
            variants={slideIn("up", "tween", 0.2, 1)}
            className={`${styles.heroHeading}  text-secondary estampa font-bold`}
          >
            Our Vision
          </motion.h1>

          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="font-normal text-justify lg:text-[18px] text-[20px] lg:text-left text-black"
          >
            At BashPlug Entertainments, our vision is to revolutionize the
            entertainment industry by discovering and nurturing exceptional
            talent. We are dedicated to providing comprehensive and strategic
            management services that empower our clients to unleash their full
            potential. With an unwavering commitment to excellence, boundless
            creativity, and relentless innovation, we strive to lead the
            industry and deliver groundbreaking ventures.
          </motion.p>
        </div>

      
      </motion.div>
    </section>
  );
};

export default Vision;
