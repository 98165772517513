import React from 'react'
import { Link, useLocation } from "react-router-dom";


const MobileMenu = ({handleClick}) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div  className={`block lg:hidden  mobile-menu absolute top-[54px] left-0 shadow-md rounded-b-lg  bg-white py-6  w-full transition ease-in duration-300` }>
      <div className="Links flex flex-col w-full items-center text-[20px] space-y-2 font-medium ">
            <Link
          onClick={handleClick}
              to="/about"
              className={`${
                splitLocation[1] === "about" ? "bg-primary text-white" : "text-secondary"
              }  transition ease-in py-2 px-4 w-full text-center`}
            >
              <h6>ABOUT</h6>
            </Link>

            <Link
              onClick={handleClick}
              to="/portfolio"
              className={`${
                splitLocation[1] === "portfolio" ? "bg-primary text-white" : "text-secondary "
              } transition ease-in  py-2 px-4 w-full text-center`}
            >
              <h6>PORTFOLIO</h6>
            </Link>

            {/* <Link
              onClick={handleClick}
              to="/contact"
              className={`${
                splitLocation[1] === "contact" ? "bg-primary text-white" : ""
              } text-secondary transition ease-in py-2 px-4`}
            >
              <h6>CONTACT</h6>
            </Link> */}

            <Link
              onClick={handleClick}
              to="/booking"
              className={`${
                splitLocation[1] === "booking" ? "bg-primary text-white" : "text-secondary "
              }  transition ease-in py-2 px-4 w-full text-center`}
            >
              <h6>BOOKINGS</h6>
            </Link>

            {/* <Link
              onClick={handleClick}
              href="#blog"
              className={`${
                splitLocation[1] === "blog" ? "bg-primary text-white" : ""
              } text-secondary transition ease-in py-2 px-4`}
            >
              <h6>BLOG</h6>
            </Link> */}
          </div>
      </div>
  )
}

export default MobileMenu