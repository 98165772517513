import React from 'react'
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";
import BashUk from "../../img/bash-mission.jpg"
import styles from "../../styles";


const Mission = () => {
  return (
    <section   className="pb-[60px]  lg:pb-[80px] px-8 lg:px-32 pt-[80px] w-[98%]  m-auto">
           <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} grid   lg:gap-0  grid-cols-1 lg:grid-cols-2 items-center `}
      >
        <div className="left-section lg:text-left text-center">
          <motion.h1
            variants={slideIn("up", "tween", 0.2, 1)}
            className={`${styles.heroHeading}  text-secondary estampa font-bold`}
          >
           Our Mission
          </motion.h1>

          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="font-normal text-justify  lg:text-[18px] text-[20px] lg:text-left text-black"
          >
       We are dedicated to shaping the entertainment industry and making a lasting impact on every project we undertake. Join us as we create a welcoming home where creativity thrives and success is born.

          </motion.p>

         
        </div>

        <div className="right-section pt-4 lg:pt-0">
          <motion.div
            variants={fadeIn("up", "tween", 0.3, 1)}
            className="lg:w-[450px] object-fit lg:ml-auto"
          >
           <img src={BashUk} alt="" srcset="" className='rounded-[30px] w-[400px] lg:ml-auto' />
          </motion.div>
        </div>
      </motion.div>
    </section>
  )
}

export default Mission