import React from "react";
import { motion } from "framer-motion";
import { FiExternalLink } from "react-icons/fi";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import { Link } from "react-router-dom";
import { Slideshow } from "../../components";

const AboutSection = () => {
  return (
    <section
      className="pb-[60px]  lg:pb-[80px] px-8 lg:px-32 pt-[80px] w-[98%]  m-auto"
     
    >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} grid grid-cols-1 lg:grid-cols-2 items-center `}
      >
        <div className="left-section lg:text-left text-center">
          <motion.h1
            variants={slideIn("up", "tween", 0.2, 1)}
            className={`${styles.heroHeading}  text-secondary estampa`}
          >
            About Us
          </motion.h1>

          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className="font-normal  lg:text-[18px] text-[20px] lg:text-left text-black"
          >
            BashPlug Entertainments is a dynamic and visionary talent management
            and PR services company founded and led by Bashorun Biola Emmanuel,
            popularly known as BashAnything. With a rich tapestry of
            versatility, passion, and an unwavering dedication to excellence,
            BashAnything has emerged as a trusted figure and tastemaker in
            various creative realms.
          </motion.p>

          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="py-5 lg:pt-10"
          >
            <Link
              to="/about"
              className="flex text-center lg:w-1/3 justify-center text-secondary hover:text-white items-center 
               h-fit py-2 px-4 lg:py-3 lg:px-6 border-2 border-secondary hover:border-primary hover:bg-primary  gap-[4px]"
            >
              <p className=" text-[16px] pt-1 font-bold">LEARN MORE</p>

              <FiExternalLink />
            </Link>
          </motion.div>
        </div>

        <div className="right-section pt-4 lg:pt-0">
          <motion.div
            variants={fadeIn("up", "tween", 0.3, 1)}
            className="lg:w-[350px] object-fit lg:ml-auto"
          >
            <Slideshow />
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default AboutSection;
