import React, {useEffect}from 'react'
import { Welcome, Form  } from '../sections/Booking'

const Booking = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (

    <>
   <Welcome/>
   <Form/>
    </>

  )
}

export default Booking