import Handshake from '../img/handshake.svg'
import Talent from '../img/talent-manager.svg'
import Producer from '../img/music-producer.svg'
import Event from '../img/event-manager.svg'
import Promoter from '../img/promoter.svg'
import Columbia from '../img/columbia.png'
import Universal from '../img/umg.png'
// import Mavins from '../img/mavin.png'
import Firece from '../img/fierce.png'
import Fount from '../img/fountrand.png'
import PromiseLand from '../img/promise-land.png'
// import Kenny from '../img/kennyblaq.jpg'
import Raybekah from '../img/raybakah.jpg'
import Spyro from '../img/spyro.jpg'
import BandHitz from '../img/bandhitz.jpg'
import { AiFillInstagram,AiFillTwitterCircle, AiFillApple } from "react-icons/ai";
import {FaSpotify} from 'react-icons/fa'
import {  FaYoutube } from "react-icons/fa";
import {IoLogoTiktok} from "react-icons/io5"



import Jay from "../img/jay.jpeg"

//jingle brand images 
import Mtn from "../img/mtn-logo.jpg"
import Airtel from "../img/airtel.jpg"
import Gtb from "../img/gtb.jpg"
import Zenith from "../img/Zenith.png"
import Fcmb from "../img/FCMB.png"
import Binatone from "../img/binatone.jpg"
import Adron from "../img/adron.jpg"
import Etisalat from "../img/9mobile.jpg"
import Lafarge from "../img/holcim.svg"
import Rite from "../img/Rite.png"
import Emzor from "../img/emzor.jpg"

//background images 
import SpyroBg from "../img/spyro-bg.jpg"
// import KennyBg from "../img/kennybg.jpg"
import RaybekahBg from "../img/raybekah-bg.jpeg"
import BandBg from "../img/band-bg.jpg"


export const services = [
  {
    id: 0,
    title: "PR Consultant",
    img: Handshake
  },
  {
    id: 1,
    title: "Talent Manager",
    img: Talent,
  },
  {
    id: 2,
    title: "Music Producer",
    img: Producer,
  },
  {
    id: 3,
    title: "Event Manager",
    img: Event,
  },
  {
    id: 4,
    title: "Music Promoter",
    img: Promoter,
  },
];


export const partners = [
{
  id: 0,
  brand:'Columbia Records',
  logo:Columbia
},

{
  id: 1,
  brand:'Promise Land',
  logo:Universal
},
// {
//   id: 2,
//   brand:'Mavins Records',
//   logo:Mavins
// },
{
  id: 2,
  brand:'Fierce Records',
  logo:Firece
},
{
  id: 3,
  brand:'Fount',
  logo:Fount
},
{
  id: 4,
  brand:'Promise LAnd',
  logo:PromiseLand
},



]


export const testimonials = [
  {
    id: 0,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 😎'
  },
  {
    id: 1,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 😇'
  },
  {
    id: 2,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 🙌🏾'
  },
  {
    id: 3,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 👩🏾'
  },
  {
    id: 4,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 🔥'
  },
  {
    id: 5,
    fullName:'Prince Jayson',
    rating: 4.5,
    img: Jay,
    comment:'Bash is a very popular and great person to work with! with over 5 years working with his brand, i\'ve never regretted working wirh him! 🥳'
  },
]
 
export const values = [
{
  id: 0,
  title: 'Professionalism',
  desc: 'Our company takes pride in our commitment to providing our clients with the best services possible. Our team of experts always strives for excellence and maintains a high level of professionalism in all aspects of our work.'

},

{
  id: 1,
  title: 'Innovation',
  desc: 'At our company, we believe that creativity and innovation are essential in turning our clients\' ideas into reality. We are always exploring new ideas and techniques to produce unique and outstanding results.'

},

{
  id: 2,
  title: 'Customer-centric',
  desc: 'We are dedicated to building strong relationships with our clients and putting their needs first. Our team takes the time to listen to our clients\' requirements and provide personalized services that meet their specific needs.'

}

]

export const talents = [
  // {
  //   id: 0,
  //   fullName: 'Kennyblaq',
  //   img: Kenny,
  //   bg: KennyBg,
  //   link:'/portfolio/kennyblaq',
  //   icons: {
  //     instagram:{
  //       icon: <AiFillInstagram/>,
  //       link: 'https://music.apple.com/us/browse'
  //     },
  //     twitter: {
  //       icon:<AiFillTwitterCircle/>,
  //       link: 'https://music.apple.com/us/browse'
  //     },
  //     apple:{
  //       icon:<AiFillApple/>,
  //       link: 'https://music.apple.com/us/browse'
  //     },
  //     spotify: {
  //       icon:<FaSpotify/>,
  //       link: 'https://music.apple.com/us/browse'
  //     },
  //     youtube:{
  //       icon:<FaYoutube/>,
  //       link: 'https://music.apple.com/us/browse'
  //     } 


  //   },
  //   video: ['https://www.youtube.com/embed/_i0PLsWlO3c',
  //    'https://www.youtube.com/embed/6s6W5KhQlhw',
  //   'https://www.youtube.com/embed/3dkdoH2vXdw',
  //   'https://www.youtube.com/embed/dg07zr4Ip2Y',
  //  'https://www.youtube.com/embed/oD1clzgVMew',
  //   'https://www.youtube.com/embed/fjss9_aoTGI']

  // },

  {
    id: 1,
    fullName: 'Raybekah',
    img: Raybekah,
    bg: RaybekahBg,
    link: '/portfolio/raybekah',
    icons: {
      instagram:{
        icon: <AiFillInstagram/>,
        link: 'https://instagram.com/raybekah_?igshid=NjIwNzIyMDk2Mg=='
      },
      twitter: { 
        icon:<AiFillTwitterCircle/>,
        link: 'https://music.apple.com/us/browse'
      },
      tiktok: {
        icon:<IoLogoTiktok/>,
        link: 'https://www.tiktok.com/@raybekah_2?_t=8fHXNCRM7bp&_r=1'
      },
      apple:{
        icon:<AiFillApple/>,
        link: 'https://music.apple.com/us/artist/raybekah/1144345890'
      },
      spotify: {
        icon:<FaSpotify/>,
        link: 'https://open.spotify.com/artist/0SwPkNmxB2YGHWVJMI8kpW?si=DZhcE_GOTWW0dcXCaKnQXw'
      },
      youtube:{
        icon:<FaYoutube/>,
        link: 'https://youtube.com/@raybekah6870?si=4bvef2ds8McvaVYv'
      } 


    },
    video: ['https://www.youtube.com/embed/1GbSAUOv_Bk',
     'https://www.youtube.com/embed/92ETpdKIdbY',
     'https://www.youtube.com/embed/4IJZXgzHNSs',
    'https://www.youtube.com/embed/ES0K6i5MoXY',
     'https://www.youtube.com/embed/krTKWUCba44',
   'https://www.youtube.com/embed/eBdf4Ww2CXg']

  },

  {
    id: 2,
    fullName: 'Bandhitz',
    img: BandHitz,
    bg: BandBg,
    link: '/portfolio/bandhitz',
    icons: {
      instagram:{
        icon: <AiFillInstagram/>,
        link: 'https://instagram.com/bandhitz_?igshid=NjIwNzIyMDk2Mg=='
      },
      twitter: {
        icon:<AiFillTwitterCircle/>,
        link: 'https://music.apple.com/us/browse'
      },
      tiktok: {
        icon:<IoLogoTiktok/>,
        link: 'http://vm.tiktok.com/ZMRkkwovC/'
      },
      apple:{
        icon:<AiFillApple/>,
        link: 'https://music.apple.com/ng/artist/bandhitz/1587431348'
      },
      spotify: {
        icon:<FaSpotify/>,
        link: 'https://open.spotify.com/artist/1GIE3J7HydWzdsAzTCY2kr?si=Zho40_TZSFuJi7h7yRsv7w'
      },
      youtube:{
        icon:<FaYoutube/>,
        link: 'http://www.youtube.com/@bandhitz'
      } 


    },
    video: [
     'https://www.youtube.com/embed/hM72jbyV4Mg',
    'https://www.youtube.com/embed/OusXjI7RFIs',
     'https://www.youtube.com/embed/CDYzhSM5l9Y',
    // 'https://www.youtube.com/embed/R4ncS6rgWT8',
    'https://www.youtube.com/embed/C3N0O30H4sc',

    ]

  },

  {
    id: 3,
    fullName: 'Spyro',
    img: Spyro,
    bg:SpyroBg,
    link: '/portfolio/spyro',
    icons: {
      instagram:{
        icon: <AiFillInstagram/>,
        link: 'https://instagram.com/spyro__official?igshid=NjIwNzIyMDk2Mg=='
      },
      twitter: {
        icon:<AiFillTwitterCircle/>,
        link: 'https://music.apple.com/us/browse'
      },
      tiktok: {
        icon:<IoLogoTiktok/>,
        link: 'https://www.tiktok.com/@spyro__official?_t=8fHXV8iJfWv&_r=1'
      },
      apple:{
        icon:<AiFillApple/>,
        link: 'https://music.apple.com/us/artist/spyro/280325068'
      },
      spotify: {
        icon:<FaSpotify/>,
        link: 'https://open.spotify.com/artist/5ZJQWu95i72nZXLD8VC5ys?si=7RxJnEdMRPyz1rGBZoT9lg'
      },
      youtube:{
        icon:<FaYoutube/>,
        link: 'https://youtube.com/@Spyro__Official?si=aMx3wMFRBnU_jBKT'
      } 


    },
    video: [
      'https://www.youtube.com/embed/wyKccbQm1f0',
   'https://www.youtube.com/embed/C1Eb8owGshc',
   'https://www.youtube.com/embed/OHnXY9F7m9c',
    ]

  },

];

export const jingles = [
{
  id: 0,
  brandName: 'Mtn',
  img: Mtn,

},
{
  id: 1,
  brandName: 'Airtel',
  img: Airtel,

},
{
  id: 2,
  brandName: 'GTBank',
  img: Gtb,

},
{
  id: 3,
  brandName: 'Zenith Bank',
  img: Zenith,

},
{
  id: 4,
  brandName: 'FCMB',
  img: Fcmb,

},
{
  id: 5,
  brandName: 'Binatone',
  img: Binatone,

},
{
  id: 6,
  brandName: 'Adron Homes',
  img: Adron,

},
{
  id: 7,
  brandName: '9mobile',
  img: Etisalat,

},
{
  id:8,
  brandName: 'Lafarge',
  img: Lafarge
},
{
  id:9,
  brandName: 'Rite sausage',
  img:Rite
},
{
  id: 10,
  brandName: 'Emzor Paracetamol ',
  img: Emzor,

}
];