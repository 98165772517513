import React from "react";
import { motion } from "framer-motion";
import PortfolioBg from "../../img/bash-bg.png";
import { slideIn, staggerContainer } from "../../utils/motion";
import styles from "../../styles";

const Welcome = () => {
  return (
    <>
      <section 
      className="  m-auto w-[98%] rounded-3xl pt-[80px] pb-[100px]  lg:pt-[100px] lg:pb-[160px]"
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(94,22,0,0.9) 0%, rgba(36,8,0,0.9) 50%, rgba(0,0,0,0.9) 100%), url(${PortfolioBg})`,
        backgroundSize: `cover`,
        backgroundPositionY: `20%`
  
        
      }}
      >
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className={`${styles.innerWidth} mx-auto flex flex-col h-full`}
        >
          <div className="text-[40px] flex items-center h-full justify-center font-bold">
            <div className="flex justify-center items-center flex-col ">
              <motion.h1
                variants={slideIn("up", "tween", 0.2, 1)}
                className={`${styles.heroHeading}  text-center text-white  estampa`}
              >
                About Us
              </motion.h1>

              <motion.p
                variants={slideIn("up", "tween", 0.2, 1)}
                className="lg:w-[70%] px-2 md:p-0 m-auto font-normal lg:text-[22px] text-[20px]  text-center text-white"
              >
               BashPlug Entertainments is a dynamic and visionary talent management and PR services company founded and led by Bashorun Biola Emmanuel, popularly known as BashAnything. With a rich tapestry of versatility, passion, and an unwavering dedication to excellence, BashAnything has done some productions which have made it to Netflix and He's emerged as a trusted figure and tastemaker in the entertainment industry.<br/> <br/> 
Bash holds a BSc degree from the University of Cape Coast and completed his ND at Yaba College of Technology (Yabatech). His exceptional track record and collaborations with industry leaders serve as a testament to his expertise and contributions to the field.

              </motion.p>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default Welcome;
