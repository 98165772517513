import React from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import { motion } from "framer-motion";
import styles from "../../styles";
import Partners  from "../../components/Partners";

const Collaboration = () => {
  return (
    <section className=" px-8 lg:px-16 pt-[40px] mt-[100px] lg:pt-[80px] pb-[40px] lg:pb-[70px]  m-auto w-full bg-slate-50 ">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
      >
        <motion.h1
          variants={slideIn("up", "tween", 0.2, 1)}
          className={`${styles.heroHeading}  text-center text-secondary  estampa`}
        >
          Collaborations and Affiliations
        </motion.h1>
        <motion.p
          variants={fadeIn("up", "tween", 0.2, 1)}
          className="lg:w-[60%] m-auto font-normal text-left  lg:text-[22px] text-[20px]  lg:text-center text-black"
        >
          BashPlug Entertainments has fostered strong collaborations and
          affiliations with renowned organizations in the music industry.
          Through our past partnerships with Universal Music Group UK, Mavin Records,
          and Columbia Records UK, we ensure that our clients receive top-tier
          opportunities and unmatched exposure. These prestigious collaborations
          position us at the forefront of industry trends, allowing us to offer
          our clients unparalleled support and resources.
        </motion.p>
      </motion.div>

      <Partners/>
    </section>
  );
};

export default Collaboration;
