import React from "react";

import { motion } from "framer-motion";
import {  slideIn, staggerContainer } from "../../utils/motion";
import ChevronWhite from "../../img/chevron-white.png"

const Events = () => {
  return (
    <section
    className=" px-8 lg:px-32  lg:pt-[80px] py-[60px] lg:pb-[70px]  m-auto w-[98%] rounded-3xl bg-gradient-to-r from-[#5E1600] via-[#240800] to-black "

  >
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className="space-y-6"
    >
      <motion.div 
         variants={slideIn("up", "tween", 0.2, 1)}
        className="left-section flex items-center gap-2 lg:text-left text-center ">
          <img src={ChevronWhite} className="w-4 h-7" alt="" srcset="" />
          <h1
          
            className={`text-white capitalize text-[16px] font-bold`}
          >
           STAGE MANAGEMENT
          </h1>

        
        </motion.div>

        <motion.p
          variants={slideIn("up", "tween", 0.2, 1)}
        className="lg:w-[45%] pb-2 estampa font-normal lg:text-[40px] text-[20px]  leading-[36px] text-left text-white"
      >
        BashPlug Entertainments is a dynamic and visionary brand
      </motion.p>

   
        <div
        
          className=" gap-4 grid grid-cols-1 lg:grid-cols-12 items-center"
        >
          <div className="lg:col-span-12">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/pkIFekqYW0U?si=4c8mJ0PX5OYyuA2s"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>
          {/* <div className="col-span-6">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/jylyDYP8QjE?si=FnZaQps1U8OGi31t"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div> */}
          <div className="lg:col-span-12">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/rPPsQDXLaaM?si=gnVMHL-Kb5lWmmyE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>

          <div className="lg:col-span-5">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/HG_bUStMvP0?si=xJuT3mATWDvkop4V"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>


          <div className="lg:col-span-7">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/sGApayhdWek?si=zTe3yHt3uvB6xBfq"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>

              <div className="lg:col-span-7">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/Rkg8A0IISgQ?si=NTYGDxWlnScxqiM1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>

          <div className="lg:col-span-5">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/oVsvKmRON-A?si=UIZfja9dfrIEzNDk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
              class="rounded-xl w-full h-96"
            ></iframe>
          </div>

       

       
     
        </div>

     

     
    </motion.div>
  </section>
  )
}

export default Events