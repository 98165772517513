import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom"; 

import { Footer, Navbar, TalentDetails} from './components'
import {About, Portfolio, Home, Booking} from './pages'
import { Talents, } from "./sections/Portfolio";
  import 'react-toastify/dist/ReactToastify.css';
  import {ToastContainer} from 'react-toastify';
  
//import { Kennyblaq, Banditz, Raybekah, Spyro } from "./pages/Portfolio";


const Layout = () => {
  return (
    <>
    <Navbar />
    <ToastContainer/>
    <Outlet />
    <Footer/>
    </>
  )
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout/>,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/portfolio',
        element: <Portfolio />
      },
      {
        path: '/portfolio/talents',
        element: <Talents />
      },
      {
        path: '/portfolio/talents/:id',
        element: <TalentDetails />

      },
      // {
      //   path: '/portfolio/kennyblaq',
      //   element: <Kennyblaq/>
      // },
      // {
      //   path: '/portfolio/raybekah',
      //   element: <Raybekah/>
      // },
      // {
      //   path: '/portfolio/spyro',
      //   element: <Spyro/>
      // },
      // {
      //   path: '/portfolio/banditz',
      //   element: <Banditz/>
      // },
      {
        path: '/booking',
        element: <Booking />
      },
    
    ]
  },

]);

function App(){
  return(
    <RouterProvider router={router}/>
  )
}

export default App